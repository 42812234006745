import router from 'next/router';
import omit from 'lodash/omit';
import {
    GAME_CATEGORY_SLOTS,
    GAME_CATEGORY_TABLE,
    GAME_CATEGORY_OTHER,
    GAME_CATEGORY_LIVE,
    GAME_ROUTER_PATHNAME,
    COUNTRY_CODE_CH,
    LANGUAGE_EN,
    LANGUAGE_FR,
    LANGUAGE_FI,
    LANGUAGE_PT_BR,
    LANGUAGE_ES_MX,
    LANGUAGE_TR_TR,
    LANGUAGE_FI_FI,
    LANGUAGE_RU_RU,
    LANGUAGE_VI_VN,
    LANGUAGE_TH_TH,
    LANGUAGE_NN_NO,
    LANGUAGE_ZN_CN,
    LANGUAGE_AR_AE,
    LANGUAGE_JA_JP,
    COUNTRY_CODE_FR,
    COUNTRY_CODE_BL,
    COUNTRY_CODE_GP,
    COUNTRY_CODE_GF,
    COUNTRY_CODE_MF,
    COUNTRY_CODE_MQ,
    COUNTRY_CODE_PM,
    COUNTRY_CODE_RE,
    COUNTRY_CODE_YT,
    COUNTRY_CODE_NC,
    COUNTRY_CODE_PF,
    COUNTRY_CODE_WF,
    COUNTRY_CODE_MC
} from '../variables';
import routes from './routes.json';

export const frenchLocations = ['FR', 'BL', 'GP', 'MF', 'MQ', 'NC', 'PF', 'PM', 'RE', 'TF', 'WF', 'GF', 'YT', 'CH'];

export const isFRLocale = (locale) => {
    return frenchLocations.includes(locale);
};

export const langToTTSLocale = (locale) => {
    const localeMap = {
        en: 'en-us',
        fr: 'fr-fr',
        de: 'de-de',
        fi: 'en-us'
    };

    return localeMap[locale] || 'en-us';
};

export const categorySlugRoutes = (paths) => ({
    [GAME_CATEGORY_SLOTS]: `/${paths[routes.slot_machines]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_TABLE]: `/${paths[routes.table_games]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_OTHER]: `/${paths[routes.other_games]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_LIVE]: paths[routes.live_casino]
});

export const getGamePath = (game, gamesData, locale) => {
    const { softwares, categories } = gamesData;
    const software = softwares.find((software) => software.id === game?.softwareId);
    const category = categories.find((category) => category.id === game?.categoryId);
    const paths = getLocalizedPath(locale);
    const categoryPath = categorySlugRoutes(paths)[category?.name];
    const gamePath = `${categoryPath}${software?.slug}/${game?.slug}-${game?.id}`;
    return game?.tableHash ? `${gamePath}#table=${game?.tableHash}` : gamePath;
};

export const getProviderPath = (provider, locale) => {
    const paths = getLocalizedPath(locale);
    return `${paths[routes.games]}?providers=${provider.slug}`;
};

export const getLocalizedPath = (locale) => {
    const paths = Object.keys(routes).reduce((a, b) => ({ ...a, [routes[b]]: routes[b] }), {});
    const localized = Object.keys(localizedPathnames).reduce((a, b) => ({ ...a, [b]: localizedPathnames[b][locale] }), {});
    return {
        ...paths,
        ...localized
    };
};

export const getLocalizedCmsSlug = (locale) => {
    switch (locale) {
    case COUNTRY_CODE_FR:
    case COUNTRY_CODE_BL:
    case COUNTRY_CODE_GF:
    case COUNTRY_CODE_GP:
    case COUNTRY_CODE_MF:
    case COUNTRY_CODE_MQ:
    case COUNTRY_CODE_PM:
    case COUNTRY_CODE_RE:
    case COUNTRY_CODE_YT:
    case COUNTRY_CODE_NC:
    case COUNTRY_CODE_PF:
    case COUNTRY_CODE_WF:
    case COUNTRY_CODE_MC:
        return {
            termsSlug: 'terms_and_conditions_fr',
            slugPrefix: `_fr`,
            footerContent: 'footer.content.fr'
        };
    default:
        return {
            termsSlug: 'terms_and_conditions',
            slugPrefix: ``,
            footerContent: 'footer.content'
        };
    }
};

export const localizedPathnames = {
    [routes.slot_machines]: {
        [LANGUAGE_EN]: routes.slot_machines,
        [LANGUAGE_FR]: '/jeux-casino/machine-a-sous/',
        [LANGUAGE_FI]: routes.slot_machines,
        [LANGUAGE_PT_BR]: routes.slot_machines,
        [LANGUAGE_ES_MX]: routes.slot_machines,
        [LANGUAGE_TR_TR]: routes.slot_machines,
        [LANGUAGE_FI_FI]: routes.slot_machines,
        [LANGUAGE_RU_RU]: routes.slot_machines,
        [LANGUAGE_VI_VN]: routes.slot_machines,
        [LANGUAGE_TH_TH]: routes.slot_machines,
        [LANGUAGE_NN_NO]: routes.slot_machines,
        [LANGUAGE_ZN_CN]: routes.slot_machines,
        [LANGUAGE_AR_AE]: routes.slot_machines,
        [LANGUAGE_JA_JP]: routes.slot_machines
    },
    ['/jeux-casino/machine-a-sous/']: {
        [LANGUAGE_EN]: routes.slot_machines,
        [LANGUAGE_FR]: '/jeux-casino/machine-a-sous/',
        [LANGUAGE_FI]: routes.slot_machines,
        [LANGUAGE_PT_BR]: routes.slot_machines,
        [LANGUAGE_ES_MX]: routes.slot_machines,
        [LANGUAGE_TR_TR]: routes.slot_machines,
        [LANGUAGE_FI_FI]: routes.slot_machines,
        [LANGUAGE_RU_RU]: routes.slot_machines,
        [LANGUAGE_VI_VN]: routes.slot_machines,
        [LANGUAGE_TH_TH]: routes.slot_machines,
        [LANGUAGE_NN_NO]: routes.slot_machines,
        [LANGUAGE_ZN_CN]: routes.slot_machines,
        [LANGUAGE_AR_AE]: routes.slot_machines,
        [LANGUAGE_JA_JP]: routes.slot_machines
    },
    [routes.table_games]: {
        [LANGUAGE_EN]: routes.table_games,
        [LANGUAGE_FR]: '/jeux-casino/jeux-de-table/',
        [LANGUAGE_FI]: routes.table_games,
        [LANGUAGE_PT_BR]: routes.table_games,
        [LANGUAGE_ES_MX]: routes.table_games,
        [LANGUAGE_TR_TR]: routes.table_games,
        [LANGUAGE_FI_FI]: routes.table_games,
        [LANGUAGE_RU_RU]: routes.table_games,
        [LANGUAGE_VI_VN]: routes.table_games,
        [LANGUAGE_TH_TH]: routes.table_games,
        [LANGUAGE_NN_NO]: routes.table_games,
        [LANGUAGE_ZN_CN]: routes.table_games,
        [LANGUAGE_AR_AE]: routes.table_games,
        [LANGUAGE_JA_JP]: routes.table_games
    },
    ['/jeux-casino/jeux-de-table/']: {
        [LANGUAGE_EN]: routes.table_games,
        [LANGUAGE_FR]: '/jeux-casino/jeux-de-table/',
        [LANGUAGE_FI]: routes.table_games,
        [LANGUAGE_PT_BR]: routes.table_games,
        [LANGUAGE_ES_MX]: routes.table_games,
        [LANGUAGE_TR_TR]: routes.table_games,
        [LANGUAGE_FI_FI]: routes.table_games,
        [LANGUAGE_RU_RU]: routes.table_games,
        [LANGUAGE_VI_VN]: routes.table_games,
        [LANGUAGE_TH_TH]: routes.table_games,
        [LANGUAGE_NN_NO]: routes.table_games,
        [LANGUAGE_ZN_CN]: routes.table_games,
        [LANGUAGE_AR_AE]: routes.table_games,
        [LANGUAGE_JA_JP]: routes.table_games
    },
    [routes.other_games]: {
        [LANGUAGE_EN]: routes.other_games,
        [LANGUAGE_FR]: '/jeux-casino/autres-jeux/',
        [LANGUAGE_FI]: routes.other_games,
        [LANGUAGE_PT_BR]: routes.other_games,
        [LANGUAGE_ES_MX]: routes.other_games,
        [LANGUAGE_TR_TR]: routes.other_games,
        [LANGUAGE_FI_FI]: routes.other_games,
        [LANGUAGE_RU_RU]: routes.other_games,
        [LANGUAGE_VI_VN]: routes.other_games,
        [LANGUAGE_TH_TH]: routes.other_games,
        [LANGUAGE_NN_NO]: routes.other_games,
        [LANGUAGE_ZN_CN]: routes.other_games,
        [LANGUAGE_AR_AE]: routes.other_games,
        [LANGUAGE_JA_JP]: routes.other_games
    },
    ['/jeux-casino/autres-jeux/']: {
        [LANGUAGE_EN]: routes.other_games,
        [LANGUAGE_FR]: '/jeux-casino/autres-jeux/',
        [LANGUAGE_FI]: routes.other_games,
        [LANGUAGE_PT_BR]: routes.other_games,
        [LANGUAGE_ES_MX]: routes.other_games,
        [LANGUAGE_TR_TR]: routes.other_games,
        [LANGUAGE_FI_FI]: routes.other_games,
        [LANGUAGE_RU_RU]: routes.other_games,
        [LANGUAGE_VI_VN]: routes.other_games,
        [LANGUAGE_TH_TH]: routes.other_games,
        [LANGUAGE_NN_NO]: routes.other_games,
        [LANGUAGE_ZN_CN]: routes.other_games,
        [LANGUAGE_AR_AE]: routes.other_games,
        [LANGUAGE_JA_JP]: routes.other_games
    },
    [routes.responsible_gaming]: {
        [LANGUAGE_EN]: routes.responsible_gaming,
        [LANGUAGE_FR]: '/jeu-responsable/',
        [LANGUAGE_FI]: routes.responsible_gaming,
        [LANGUAGE_PT_BR]: routes.responsible_gaming,
        [LANGUAGE_ES_MX]: routes.responsible_gaming,
        [LANGUAGE_TR_TR]: routes.responsible_gaming,
        [LANGUAGE_FI_FI]: routes.responsible_gaming,
        [LANGUAGE_RU_RU]: routes.responsible_gaming,
        [LANGUAGE_VI_VN]: routes.responsible_gaming,
        [LANGUAGE_TH_TH]: routes.responsible_gaming,
        [LANGUAGE_NN_NO]: routes.responsible_gaming,
        [LANGUAGE_ZN_CN]: routes.responsible_gaming,
        [LANGUAGE_AR_AE]: routes.responsible_gaming,
        [LANGUAGE_JA_JP]: routes.responsible_gaming
    },
    ['/jeu-responsable/']: {
        [LANGUAGE_EN]: routes.responsible_gaming,
        [LANGUAGE_FR]: '/jeu-responsable/',
        [LANGUAGE_FI]: routes.responsible_gaming,
        [LANGUAGE_PT_BR]: routes.responsible_gaming,
        [LANGUAGE_ES_MX]: routes.responsible_gaming,
        [LANGUAGE_TR_TR]: routes.responsible_gaming,
        [LANGUAGE_FI_FI]: routes.responsible_gaming,
        [LANGUAGE_RU_RU]: routes.responsible_gaming,
        [LANGUAGE_VI_VN]: routes.responsible_gaming,
        [LANGUAGE_TH_TH]: routes.responsible_gaming,
        [LANGUAGE_NN_NO]: routes.responsible_gaming,
        [LANGUAGE_ZN_CN]: routes.responsible_gaming,
        [LANGUAGE_AR_AE]: routes.responsible_gaming,
        [LANGUAGE_JA_JP]: routes.responsible_gaming
    },
    [routes.live_casino]: {
        [LANGUAGE_EN]: routes.live_casino,
        [LANGUAGE_FR]: routes.live_casino,
        [LANGUAGE_FI]: routes.live_casino,
        [LANGUAGE_PT_BR]: routes.live_casino,
        [LANGUAGE_ES_MX]: routes.live_casino,
        [LANGUAGE_TR_TR]: routes.live_casino,
        [LANGUAGE_FI_FI]: routes.live_casino,
        [LANGUAGE_RU_RU]: routes.live_casino,
        [LANGUAGE_VI_VN]: routes.live_casino,
        [LANGUAGE_TH_TH]: routes.live_casino,
        [LANGUAGE_NN_NO]: routes.live_casino,
        [LANGUAGE_ZN_CN]: routes.live_casino,
        [LANGUAGE_AR_AE]: routes.live_casino,
        [LANGUAGE_JA_JP]: routes.live_casino
    },
    [routes.live_casino_games]: {
        [LANGUAGE_EN]: routes.live_casino_games,
        [LANGUAGE_FR]: '/live-casino/jeux/',
        [LANGUAGE_FI]: routes.live_casino_games,
        [LANGUAGE_PT_BR]: routes.live_casino_games,
        [LANGUAGE_ES_MX]: routes.live_casino_games,
        [LANGUAGE_TR_TR]: routes.live_casino_games,
        [LANGUAGE_FI_FI]: routes.live_casino_games,
        [LANGUAGE_RU_RU]: routes.live_casino_games,
        [LANGUAGE_VI_VN]: routes.live_casino_games,
        [LANGUAGE_TH_TH]: routes.live_casino_games,
        [LANGUAGE_NN_NO]: routes.live_casino_games,
        [LANGUAGE_ZN_CN]: routes.live_casino_games,
        [LANGUAGE_AR_AE]: routes.live_casino_games,
        [LANGUAGE_JA_JP]: routes.live_casino_games
    },
    ['/live-casino/jeux/']: {
        [LANGUAGE_EN]: routes.live_casino_games,
        [LANGUAGE_FR]: '/live-casino/jeux/',
        [LANGUAGE_FI]: routes.live_casino_games,
        [LANGUAGE_PT_BR]: routes.live_casino_games,
        [LANGUAGE_ES_MX]: routes.live_casino_games,
        [LANGUAGE_TR_TR]: routes.live_casino_games,
        [LANGUAGE_FI_FI]: routes.live_casino_games,
        [LANGUAGE_RU_RU]: routes.live_casino_games,
        [LANGUAGE_VI_VN]: routes.live_casino_games,
        [LANGUAGE_TH_TH]: routes.live_casino_games,
        [LANGUAGE_NN_NO]: routes.live_casino_games,
        [LANGUAGE_ZN_CN]: routes.live_casino_games,
        [LANGUAGE_AR_AE]: routes.live_casino_games,
        [LANGUAGE_JA_JP]: routes.live_casino_games
    },
    [routes.games_lobby]: {
        [LANGUAGE_EN]: routes.games_lobby,
        [LANGUAGE_FR]: routes.games_lobby,
        [LANGUAGE_FI]: routes.games_lobby,
        [LANGUAGE_PT_BR]: routes.games_lobby,
        [LANGUAGE_ES_MX]: routes.games_lobby,
        [LANGUAGE_TR_TR]: routes.games_lobby,
        [LANGUAGE_FI_FI]: routes.games_lobby,
        [LANGUAGE_RU_RU]: routes.games_lobby,
        [LANGUAGE_VI_VN]: routes.games_lobby,
        [LANGUAGE_TH_TH]: routes.games_lobby,
        [LANGUAGE_NN_NO]: routes.games_lobby,
        [LANGUAGE_ZN_CN]: routes.games_lobby,
        [LANGUAGE_AR_AE]: routes.games_lobby,
        [LANGUAGE_JA_JP]: routes.games_lobby
    },
    [routes.favorite_games]: {
        [LANGUAGE_EN]: routes.favorite_games,
        [LANGUAGE_FR]: '/jeux-casino/favoris/',
        [LANGUAGE_FI]: routes.favorite_games,
        [LANGUAGE_PT_BR]: routes.favorite_games,
        [LANGUAGE_ES_MX]: routes.favorite_games,
        [LANGUAGE_TR_TR]: routes.favorite_games,
        [LANGUAGE_FI_FI]: routes.favorite_games,
        [LANGUAGE_RU_RU]: routes.favorite_games,
        [LANGUAGE_VI_VN]: routes.favorite_games,
        [LANGUAGE_TH_TH]: routes.favorite_games,
        [LANGUAGE_NN_NO]: routes.favorite_games,
        [LANGUAGE_ZN_CN]: routes.favorite_games,
        [LANGUAGE_AR_AE]: routes.favorite_games,
        [LANGUAGE_JA_JP]: routes.favorite_games
    },
    ['/jeux-casino/favoris/']: {
        [LANGUAGE_EN]: routes.favorite_games,
        [LANGUAGE_FR]: '/jeux-casino/favoris/',
        [LANGUAGE_FI]: routes.favorite_games,
        [LANGUAGE_PT_BR]: routes.favorite_games,
        [LANGUAGE_ES_MX]: routes.favorite_games,
        [LANGUAGE_TR_TR]: routes.favorite_games,
        [LANGUAGE_FI_FI]: routes.favorite_games,
        [LANGUAGE_RU_RU]: routes.favorite_games,
        [LANGUAGE_VI_VN]: routes.favorite_games,
        [LANGUAGE_TH_TH]: routes.favorite_games,
        [LANGUAGE_NN_NO]: routes.favorite_games,
        [LANGUAGE_ZN_CN]: routes.favorite_games,
        [LANGUAGE_AR_AE]: routes.favorite_games,
        [LANGUAGE_JA_JP]: routes.favorite_games
    },
    [routes.recently_played]: {
        [LANGUAGE_EN]: routes.recently_played,
        [LANGUAGE_FR]: '/jeux-casino/joue-recemment/',
        [LANGUAGE_FI]: routes.recently_played,
        [LANGUAGE_PT_BR]: routes.recently_played,
        [LANGUAGE_ES_MX]: routes.recently_played,
        [LANGUAGE_TR_TR]: routes.recently_played,
        [LANGUAGE_FI_FI]: routes.recently_played,
        [LANGUAGE_RU_RU]: routes.recently_played,
        [LANGUAGE_VI_VN]: routes.recently_played,
        [LANGUAGE_TH_TH]: routes.recently_played,
        [LANGUAGE_NN_NO]: routes.recently_played,
        [LANGUAGE_ZN_CN]: routes.recently_played,
        [LANGUAGE_AR_AE]: routes.recently_played,
        [LANGUAGE_JA_JP]: routes.recently_played
    },
    ['/jeux-casino/joue-recemment/']: {
        [LANGUAGE_EN]: routes.recently_played,
        [LANGUAGE_FR]: '/jeux-casino/joue-recemment/',
        [LANGUAGE_FI]: routes.recently_played,
        [LANGUAGE_PT_BR]: routes.recently_played,
        [LANGUAGE_ES_MX]: routes.recently_played,
        [LANGUAGE_TR_TR]: routes.recently_played,
        [LANGUAGE_FI_FI]: routes.recently_played,
        [LANGUAGE_RU_RU]: routes.recently_played,
        [LANGUAGE_VI_VN]: routes.recently_played,
        [LANGUAGE_TH_TH]: routes.recently_played,
        [LANGUAGE_NN_NO]: routes.recently_played,
        [LANGUAGE_ZN_CN]: routes.recently_played,
        [LANGUAGE_AR_AE]: routes.recently_played,
        [LANGUAGE_JA_JP]: routes.recently_played
    },
    [routes.bonuses]: {
        [LANGUAGE_EN]: routes.bonuses,
        [LANGUAGE_FR]: routes.bonuses,
        [LANGUAGE_FI]: routes.bonuses,
        [LANGUAGE_PT_BR]: routes.bonuses,
        [LANGUAGE_ES_MX]: routes.bonuses,
        [LANGUAGE_TR_TR]: routes.bonuses,
        [LANGUAGE_FI_FI]: routes.bonuses,
        [LANGUAGE_RU_RU]: routes.bonuses,
        [LANGUAGE_VI_VN]: routes.bonuses,
        [LANGUAGE_TH_TH]: routes.bonuses,
        [LANGUAGE_NN_NO]: routes.bonuses,
        [LANGUAGE_ZN_CN]: routes.bonuses,
        [LANGUAGE_AR_AE]: routes.bonuses,
        [LANGUAGE_JA_JP]: routes.bonuses
    },
    [routes.promotions]: {
        [LANGUAGE_EN]: routes.promotions,
        [LANGUAGE_FR]: routes.promotions,
        [LANGUAGE_FI]: routes.promotions,
        [LANGUAGE_PT_BR]: routes.promotions,
        [LANGUAGE_ES_MX]: routes.promotions,
        [LANGUAGE_TR_TR]: routes.promotions,
        [LANGUAGE_FI_FI]: routes.promotions,
        [LANGUAGE_RU_RU]: routes.promotions,
        [LANGUAGE_VI_VN]: routes.promotions,
        [LANGUAGE_TH_TH]: routes.promotions,
        [LANGUAGE_NN_NO]: routes.promotions,
        [LANGUAGE_ZN_CN]: routes.promotions,
        [LANGUAGE_AR_AE]: routes.promotions,
        [LANGUAGE_JA_JP]: routes.promotions
    },
    [routes.account_profile]: {
        [LANGUAGE_EN]: routes.account_profile,
        [LANGUAGE_FR]: routes.account_profile,
        [LANGUAGE_FI]: routes.account_profile,
        [LANGUAGE_PT_BR]: routes.account_profile,
        [LANGUAGE_ES_MX]: routes.account_profile,
        [LANGUAGE_TR_TR]: routes.account_profile,
        [LANGUAGE_FI_FI]: routes.account_profile,
        [LANGUAGE_RU_RU]: routes.account_profile,
        [LANGUAGE_VI_VN]: routes.account_profile,
        [LANGUAGE_TH_TH]: routes.account_profile,
        [LANGUAGE_NN_NO]: routes.account_profile,
        [LANGUAGE_ZN_CN]: routes.account_profile,
        [LANGUAGE_AR_AE]: routes.account_profile,
        [LANGUAGE_JA_JP]: routes.account_profile
    },
    [routes.bet_race]: {
        [LANGUAGE_EN]: routes.bet_race,
        [LANGUAGE_FR]: routes.bet_race,
        [LANGUAGE_FI]: routes.bet_race,
        [LANGUAGE_PT_BR]: routes.bet_race,
        [LANGUAGE_ES_MX]: routes.bet_race,
        [LANGUAGE_TR_TR]: routes.bet_race,
        [LANGUAGE_FI_FI]: routes.bet_race,
        [LANGUAGE_RU_RU]: routes.bet_race,
        [LANGUAGE_VI_VN]: routes.bet_race,
        [LANGUAGE_TH_TH]: routes.bet_race,
        [LANGUAGE_NN_NO]: routes.bet_race,
        [LANGUAGE_ZN_CN]: routes.bet_race,
        [LANGUAGE_AR_AE]: routes.bet_race,
        [LANGUAGE_JA_JP]: routes.bet_race
    },
    [routes.vip]: {
        [LANGUAGE_EN]: routes.vip,
        [LANGUAGE_FR]: routes.vip,
        [LANGUAGE_FI]: routes.vip,
        [LANGUAGE_PT_BR]: routes.vip,
        [LANGUAGE_ES_MX]: routes.vip,
        [LANGUAGE_TR_TR]: routes.vip,
        [LANGUAGE_FI_FI]: routes.vip,
        [LANGUAGE_RU_RU]: routes.vip,
        [LANGUAGE_VI_VN]: routes.vip,
        [LANGUAGE_TH_TH]: routes.vip,
        [LANGUAGE_NN_NO]: routes.vip,
        [LANGUAGE_ZN_CN]: routes.vip,
        [LANGUAGE_AR_AE]: routes.vip,
        [LANGUAGE_JA_JP]: routes.vip
    },
    [routes.register]: {
        [LANGUAGE_EN]: routes.register,
        [LANGUAGE_FR]: '/inscription/',
        [LANGUAGE_FI]: routes.register,
        [LANGUAGE_PT_BR]: routes.register,
        [LANGUAGE_ES_MX]: routes.register,
        [LANGUAGE_TR_TR]: routes.register,
        [LANGUAGE_FI_FI]: routes.register,
        [LANGUAGE_RU_RU]: routes.register,
        [LANGUAGE_VI_VN]: routes.register,
        [LANGUAGE_TH_TH]: routes.register,
        [LANGUAGE_NN_NO]: routes.register,
        [LANGUAGE_ZN_CN]: routes.register,
        [LANGUAGE_AR_AE]: routes.register,
        [LANGUAGE_JA_JP]: routes.register
    },
    ['/inscription/']: {
        [LANGUAGE_EN]: routes.register,
        [LANGUAGE_FR]: '/inscription/',
        [LANGUAGE_FI]: routes.register,
        [LANGUAGE_PT_BR]: routes.register,
        [LANGUAGE_ES_MX]: routes.register,
        [LANGUAGE_TR_TR]: routes.register,
        [LANGUAGE_FI_FI]: routes.register,
        [LANGUAGE_RU_RU]: routes.register,
        [LANGUAGE_VI_VN]: routes.register,
        [LANGUAGE_TH_TH]: routes.register,
        [LANGUAGE_NN_NO]: routes.register,
        [LANGUAGE_ZN_CN]: routes.register,
        [LANGUAGE_AR_AE]: routes.register,
        [LANGUAGE_JA_JP]: routes.register
    },
    [routes.about_us]: {
        [LANGUAGE_EN]: routes.about_us,
        [LANGUAGE_FR]: '/a-propos/',
        [LANGUAGE_FI]: routes.about_us,
        [LANGUAGE_PT_BR]: routes.about_us,
        [LANGUAGE_ES_MX]: routes.about_us,
        [LANGUAGE_TR_TR]: routes.about_us,
        [LANGUAGE_FI_FI]: routes.about_us,
        [LANGUAGE_RU_RU]: routes.about_us,
        [LANGUAGE_VI_VN]: routes.about_us,
        [LANGUAGE_TH_TH]: routes.about_us,
        [LANGUAGE_NN_NO]: routes.about_us,
        [LANGUAGE_ZN_CN]: routes.about_us,
        [LANGUAGE_AR_AE]: routes.about_us,
        [LANGUAGE_JA_JP]: routes.about_us
    },
    ['/a-propos/']: {
        [LANGUAGE_EN]: routes.about_us,
        [LANGUAGE_FR]: '/a-propos/',
        [LANGUAGE_FI]: routes.about_us,
        [LANGUAGE_PT_BR]: routes.about_us,
        [LANGUAGE_ES_MX]: routes.about_us,
        [LANGUAGE_TR_TR]: routes.about_us,
        [LANGUAGE_FI_FI]: routes.about_us,
        [LANGUAGE_RU_RU]: routes.about_us,
        [LANGUAGE_VI_VN]: routes.about_us,
        [LANGUAGE_TH_TH]: routes.about_us,
        [LANGUAGE_NN_NO]: routes.about_us,
        [LANGUAGE_ZN_CN]: routes.about_us,
        [LANGUAGE_AR_AE]: routes.about_us,
        [LANGUAGE_JA_JP]: routes.about_us
    },
    [routes.contact]: {
        [LANGUAGE_EN]: routes.contact,
        [LANGUAGE_FR]: routes.contact,
        [LANGUAGE_FI]: routes.contact,
        [LANGUAGE_PT_BR]: routes.contact,
        [LANGUAGE_ES_MX]: routes.contact,
        [LANGUAGE_TR_TR]: routes.contact,
        [LANGUAGE_FI_FI]: routes.contact,
        [LANGUAGE_RU_RU]: routes.contact,
        [LANGUAGE_VI_VN]: routes.contact,
        [LANGUAGE_TH_TH]: routes.contact,
        [LANGUAGE_NN_NO]: routes.contact,
        [LANGUAGE_ZN_CN]: routes.contact,
        [LANGUAGE_AR_AE]: routes.contact,
        [LANGUAGE_JA_JP]: routes.contact
    },
    [routes.terms]: {
        [LANGUAGE_EN]: routes.terms,
        [LANGUAGE_FR]: '/cgu/',
        [LANGUAGE_FI]: routes.terms,
        [LANGUAGE_PT_BR]: routes.terms,
        [LANGUAGE_ES_MX]: routes.terms,
        [LANGUAGE_TR_TR]: routes.terms,
        [LANGUAGE_FI_FI]: routes.terms,
        [LANGUAGE_RU_RU]: routes.terms,
        [LANGUAGE_VI_VN]: routes.terms,
        [LANGUAGE_TH_TH]: routes.terms,
        [LANGUAGE_NN_NO]: routes.terms,
        [LANGUAGE_ZN_CN]: routes.terms,
        [LANGUAGE_AR_AE]: routes.terms,
        [LANGUAGE_JA_JP]: routes.terms
    },
    ['/cgu/']: {
        [LANGUAGE_EN]: routes.terms,
        [LANGUAGE_FR]: '/cgu/',
        [LANGUAGE_FI]: routes.terms,
        [LANGUAGE_PT_BR]: routes.terms,
        [LANGUAGE_ES_MX]: routes.terms,
        [LANGUAGE_TR_TR]: routes.terms,
        [LANGUAGE_FI_FI]: routes.terms,
        [LANGUAGE_RU_RU]: routes.terms,
        [LANGUAGE_VI_VN]: routes.terms,
        [LANGUAGE_TH_TH]: routes.terms,
        [LANGUAGE_NN_NO]: routes.terms,
        [LANGUAGE_ZN_CN]: routes.terms,
        [LANGUAGE_AR_AE]: routes.terms,
        [LANGUAGE_JA_JP]: routes.terms
    },
    [routes.privacy_policy]: {
        [LANGUAGE_EN]: routes.privacy_policy,
        [LANGUAGE_FR]: '/politique-de-confidentialite/',
        [LANGUAGE_FI]: routes.privacy_policy,
        [LANGUAGE_PT_BR]: routes.privacy_policy,
        [LANGUAGE_ES_MX]: routes.privacy_policy,
        [LANGUAGE_TR_TR]: routes.privacy_policy,
        [LANGUAGE_FI_FI]: routes.privacy_policy,
        [LANGUAGE_RU_RU]: routes.privacy_policy,
        [LANGUAGE_VI_VN]: routes.privacy_policy,
        [LANGUAGE_TH_TH]: routes.privacy_policy,
        [LANGUAGE_NN_NO]: routes.privacy_policy,
        [LANGUAGE_ZN_CN]: routes.privacy_policy,
        [LANGUAGE_AR_AE]: routes.privacy_policy,
        [LANGUAGE_JA_JP]: routes.privacy_policy
    },
    ['/politique-de-confidentialite/']: {
        [LANGUAGE_EN]: routes.privacy_policy,
        [LANGUAGE_FR]: '/politique-de-confidentialite/',
        [LANGUAGE_FI]: routes.privacy_policy,
        [LANGUAGE_PT_BR]: routes.privacy_policy,
        [LANGUAGE_ES_MX]: routes.privacy_policy,
        [LANGUAGE_TR_TR]: routes.privacy_policy,
        [LANGUAGE_FI_FI]: routes.privacy_policy,
        [LANGUAGE_RU_RU]: routes.privacy_policy,
        [LANGUAGE_VI_VN]: routes.privacy_policy,
        [LANGUAGE_TH_TH]: routes.privacy_policy,
        [LANGUAGE_NN_NO]: routes.privacy_policy,
        [LANGUAGE_ZN_CN]: routes.privacy_policy,
        [LANGUAGE_AR_AE]: routes.privacy_policy,
        [LANGUAGE_JA_JP]: routes.privacy_policy
    },
    [routes.kyc]: {
        [LANGUAGE_EN]: routes.kyc,
        [LANGUAGE_FR]: routes.kyc,
        [LANGUAGE_FI]: routes.kyc,
        [LANGUAGE_PT_BR]: routes.kyc,
        [LANGUAGE_ES_MX]: routes.kyc,
        [LANGUAGE_TR_TR]: routes.kyc,
        [LANGUAGE_FI_FI]: routes.kyc,
        [LANGUAGE_RU_RU]: routes.kyc,
        [LANGUAGE_VI_VN]: routes.kyc,
        [LANGUAGE_TH_TH]: routes.kyc,
        [LANGUAGE_NN_NO]: routes.kyc,
        [LANGUAGE_ZN_CN]: routes.kyc,
        [LANGUAGE_AR_AE]: routes.kyc,
        [LANGUAGE_JA_JP]: routes.kyc
    },
    [routes.self_exclusion]: {
        [LANGUAGE_EN]: routes.self_exclusion,
        [LANGUAGE_FR]: '/auto-exclusion/',
        [LANGUAGE_FI]: routes.self_exclusion,
        [LANGUAGE_PT_BR]: routes.self_exclusion,
        [LANGUAGE_ES_MX]: routes.self_exclusion,
        [LANGUAGE_TR_TR]: routes.self_exclusion,
        [LANGUAGE_FI_FI]: routes.self_exclusion,
        [LANGUAGE_RU_RU]: routes.self_exclusion,
        [LANGUAGE_VI_VN]: routes.self_exclusion,
        [LANGUAGE_TH_TH]: routes.self_exclusion,
        [LANGUAGE_NN_NO]: routes.self_exclusion,
        [LANGUAGE_ZN_CN]: routes.self_exclusion,
        [LANGUAGE_AR_AE]: routes.self_exclusion,
        [LANGUAGE_JA_JP]: routes.self_exclusion
    },
    ['/auto-exclusion/']: {
        [LANGUAGE_EN]: routes.self_exclusion,
        [LANGUAGE_FR]: '/auto-exclusion/',
        [LANGUAGE_FI]: routes.self_exclusion,
        [LANGUAGE_PT_BR]: routes.self_exclusion,
        [LANGUAGE_ES_MX]: routes.self_exclusion,
        [LANGUAGE_TR_TR]: routes.self_exclusion,
        [LANGUAGE_FI_FI]: routes.self_exclusion,
        [LANGUAGE_RU_RU]: routes.self_exclusion,
        [LANGUAGE_VI_VN]: routes.self_exclusion,
        [LANGUAGE_TH_TH]: routes.self_exclusion,
        [LANGUAGE_NN_NO]: routes.self_exclusion,
        [LANGUAGE_ZN_CN]: routes.self_exclusion,
        [LANGUAGE_AR_AE]: routes.self_exclusion,
        [LANGUAGE_JA_JP]: routes.self_exclusion
    },
    [routes.dispute_resolution]: {
        [LANGUAGE_EN]: routes.dispute_resolution,
        [LANGUAGE_FR]: '/litiges/',
        [LANGUAGE_FI]: routes.dispute_resolution,
        [LANGUAGE_PT_BR]: routes.dispute_resolution,
        [LANGUAGE_ES_MX]: routes.dispute_resolution,
        [LANGUAGE_TR_TR]: routes.dispute_resolution,
        [LANGUAGE_FI_FI]: routes.dispute_resolution,
        [LANGUAGE_RU_RU]: routes.dispute_resolution,
        [LANGUAGE_VI_VN]: routes.dispute_resolution,
        [LANGUAGE_TH_TH]: routes.dispute_resolution,
        [LANGUAGE_NN_NO]: routes.dispute_resolution,
        [LANGUAGE_ZN_CN]: routes.dispute_resolution,
        [LANGUAGE_AR_AE]: routes.dispute_resolution,
        [LANGUAGE_JA_JP]: routes.dispute_resolution
    },
    ['/litiges/']: {
        [LANGUAGE_EN]: routes.dispute_resolution,
        [LANGUAGE_FR]: '/litiges/',
        [LANGUAGE_FI]: routes.dispute_resolution,
        [LANGUAGE_PT_BR]: routes.dispute_resolution,
        [LANGUAGE_ES_MX]: routes.dispute_resolution,
        [LANGUAGE_TR_TR]: routes.dispute_resolution,
        [LANGUAGE_FI_FI]: routes.dispute_resolution,
        [LANGUAGE_RU_RU]: routes.dispute_resolution,
        [LANGUAGE_VI_VN]: routes.dispute_resolution,
        [LANGUAGE_TH_TH]: routes.dispute_resolution,
        [LANGUAGE_NN_NO]: routes.dispute_resolution,
        [LANGUAGE_ZN_CN]: routes.dispute_resolution,
        [LANGUAGE_AR_AE]: routes.dispute_resolution,
        [LANGUAGE_JA_JP]: routes.dispute_resolution
    },
    [routes.amlkyc]: {
        [LANGUAGE_EN]: routes.amlkyc,
        [LANGUAGE_FR]: '/aml-kyc/',
        [LANGUAGE_FI]: routes.amlkyc,
        [LANGUAGE_PT_BR]: routes.amlkyc,
        [LANGUAGE_ES_MX]: routes.amlkyc,
        [LANGUAGE_TR_TR]: routes.amlkyc,
        [LANGUAGE_FI_FI]: routes.amlkyc,
        [LANGUAGE_RU_RU]: routes.amlkyc,
        [LANGUAGE_VI_VN]: routes.amlkyc,
        [LANGUAGE_TH_TH]: routes.amlkyc,
        [LANGUAGE_NN_NO]: routes.amlkyc,
        [LANGUAGE_ZN_CN]: routes.amlkyc,
        [LANGUAGE_AR_AE]: routes.amlkyc,
        [LANGUAGE_JA_JP]: routes.amlkyc
    },
    ['/aml-kyc/']: {
        [LANGUAGE_EN]: routes.amlkyc,
        [LANGUAGE_FR]: '/aml-kyc/',
        [LANGUAGE_FI]: routes.amlkyc,
        [LANGUAGE_PT_BR]: routes.amlkyc,
        [LANGUAGE_ES_MX]: routes.amlkyc,
        [LANGUAGE_TR_TR]: routes.amlkyc,
        [LANGUAGE_FI_FI]: routes.amlkyc,
        [LANGUAGE_RU_RU]: routes.amlkyc,
        [LANGUAGE_VI_VN]: routes.amlkyc,
        [LANGUAGE_TH_TH]: routes.amlkyc,
        [LANGUAGE_NN_NO]: routes.amlkyc,
        [LANGUAGE_ZN_CN]: routes.amlkyc,
        [LANGUAGE_AR_AE]: routes.amlkyc,
        [LANGUAGE_JA_JP]: routes.amlkyc
    },
    [routes.fairness_rng_testing_methods]: {
        [LANGUAGE_EN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_FR]: '/equite-gna/',
        [LANGUAGE_FI]: routes.fairness_rng_testing_methods,
        [LANGUAGE_PT_BR]: routes.fairness_rng_testing_methods,
        [LANGUAGE_ES_MX]: routes.fairness_rng_testing_methods,
        [LANGUAGE_TR_TR]: routes.fairness_rng_testing_methods,
        [LANGUAGE_FI_FI]: routes.fairness_rng_testing_methods,
        [LANGUAGE_RU_RU]: routes.fairness_rng_testing_methods,
        [LANGUAGE_VI_VN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_TH_TH]: routes.fairness_rng_testing_methods,
        [LANGUAGE_NN_NO]: routes.fairness_rng_testing_methods,
        [LANGUAGE_ZN_CN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_AR_AE]: routes.fairness_rng_testing_methods,
        [LANGUAGE_JA_JP]: routes.fairness_rng_testing_methods
    },
    ['/equite-gna/']: {
        [LANGUAGE_EN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_FR]: '/equite-gna/',
        [LANGUAGE_FI]: routes.fairness_rng_testing_methods,
        [LANGUAGE_PT_BR]: routes.fairness_rng_testing_methods,
        [LANGUAGE_ES_MX]: routes.fairness_rng_testing_methods,
        [LANGUAGE_TR_TR]: routes.fairness_rng_testing_methods,
        [LANGUAGE_FI_FI]: routes.fairness_rng_testing_methods,
        [LANGUAGE_RU_RU]: routes.fairness_rng_testing_methods,
        [LANGUAGE_VI_VN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_TH_TH]: routes.fairness_rng_testing_methods,
        [LANGUAGE_NN_NO]: routes.fairness_rng_testing_methods,
        [LANGUAGE_ZN_CN]: routes.fairness_rng_testing_methods,
        [LANGUAGE_AR_AE]: routes.fairness_rng_testing_methods,
        [LANGUAGE_JA_JP]: routes.fairness_rng_testing_methods
    },
    [routes.pay_outs_bonuses]: {
        [LANGUAGE_EN]: routes.pay_outs_bonuses,
        [LANGUAGE_FR]: '/comptes-retraits-bonus/',
        [LANGUAGE_FI]: routes.pay_outs_bonuses,
        [LANGUAGE_PT_BR]: routes.pay_outs_bonuses,
        [LANGUAGE_ES_MX]: routes.pay_outs_bonuses,
        [LANGUAGE_TR_TR]: routes.pay_outs_bonuses,
        [LANGUAGE_FI_FI]: routes.pay_outs_bonuses,
        [LANGUAGE_RU_RU]: routes.pay_outs_bonuses,
        [LANGUAGE_VI_VN]: routes.pay_outs_bonuses,
        [LANGUAGE_TH_TH]: routes.pay_outs_bonuses,
        [LANGUAGE_NN_NO]: routes.pay_outs_bonuses,
        [LANGUAGE_ZN_CN]: routes.pay_outs_bonuses,
        [LANGUAGE_AR_AE]: routes.pay_outs_bonuses,
        [LANGUAGE_JA_JP]: routes.pay_outs_bonuses
    },
    ['/comptes-retraits-bonus/']: {
        [LANGUAGE_EN]: routes.pay_outs_bonuses,
        [LANGUAGE_FR]: '/comptes-retraits-bonus/',
        [LANGUAGE_FI]: routes.pay_outs_bonuses,
        [LANGUAGE_PT_BR]: routes.pay_outs_bonuses,
        [LANGUAGE_ES_MX]: routes.pay_outs_bonuses,
        [LANGUAGE_TR_TR]: routes.pay_outs_bonuses,
        [LANGUAGE_FI_FI]: routes.pay_outs_bonuses,
        [LANGUAGE_RU_RU]: routes.pay_outs_bonuses,
        [LANGUAGE_VI_VN]: routes.pay_outs_bonuses,
        [LANGUAGE_TH_TH]: routes.pay_outs_bonuses,
        [LANGUAGE_NN_NO]: routes.pay_outs_bonuses,
        [LANGUAGE_ZN_CN]: routes.pay_outs_bonuses,
        [LANGUAGE_AR_AE]: routes.pay_outs_bonuses,
        [LANGUAGE_JA_JP]: routes.pay_outs_bonuses
    },
    [routes.login]: {
        [LANGUAGE_EN]: routes.login,
        [LANGUAGE_FR]: routes.login,
        [LANGUAGE_FI]: routes.login,
        [LANGUAGE_PT_BR]: routes.login,
        [LANGUAGE_ES_MX]: routes.login,
        [LANGUAGE_TR_TR]: routes.login,
        [LANGUAGE_FI_FI]: routes.login,
        [LANGUAGE_RU_RU]: routes.login,
        [LANGUAGE_VI_VN]: routes.login,
        [LANGUAGE_TH_TH]: routes.login,
        [LANGUAGE_NN_NO]: routes.login,
        [LANGUAGE_ZN_CN]: routes.login,
        [LANGUAGE_AR_AE]: routes.login,
        [LANGUAGE_JA_JP]: routes.login
    },
    [routes.games]: {
        [LANGUAGE_EN]: routes.games,
        [LANGUAGE_FR]: '/jeux-casino/',
        [LANGUAGE_FI]: routes.games,
        [LANGUAGE_PT_BR]: routes.games,
        [LANGUAGE_ES_MX]: routes.games,
        [LANGUAGE_TR_TR]: routes.games,
        [LANGUAGE_FI_FI]: routes.games,
        [LANGUAGE_RU_RU]: routes.games,
        [LANGUAGE_VI_VN]: routes.games,
        [LANGUAGE_TH_TH]: routes.games,
        [LANGUAGE_NN_NO]: routes.games,
        [LANGUAGE_ZN_CN]: routes.games,
        [LANGUAGE_AR_AE]: routes.games,
        [LANGUAGE_JA_JP]: routes.games
    },
    ['/jeux-casino/']: {
        [LANGUAGE_EN]: routes.games,
        [LANGUAGE_FR]: '/jeux-casino/',
        [LANGUAGE_FI]: routes.games,
        [LANGUAGE_PT_BR]: routes.games,
        [LANGUAGE_ES_MX]: routes.games,
        [LANGUAGE_TR_TR]: routes.games,
        [LANGUAGE_FI_FI]: routes.games,
        [LANGUAGE_RU_RU]: routes.games,
        [LANGUAGE_VI_VN]: routes.games,
        [LANGUAGE_TH_TH]: routes.games,
        [LANGUAGE_NN_NO]: routes.games,
        [LANGUAGE_ZN_CN]: routes.games,
        [LANGUAGE_AR_AE]: routes.games,
        [LANGUAGE_JA_JP]: routes.games
    },
    [routes.online_roulette]: {
        [LANGUAGE_EN]: routes.online_roulette,
        [LANGUAGE_FR]: '/roulette-en-ligne/',
        [LANGUAGE_FI]: '/online-ruletti/',
        [LANGUAGE_PT_BR]: routes.online_roulette,
        [LANGUAGE_ES_MX]: routes.online_roulette,
        [LANGUAGE_TR_TR]: routes.online_roulette,
        [LANGUAGE_FI_FI]: routes.online_roulette,
        [LANGUAGE_RU_RU]: routes.online_roulette,
        [LANGUAGE_VI_VN]: routes.online_roulette,
        [LANGUAGE_TH_TH]: routes.online_roulette,
        [LANGUAGE_NN_NO]: routes.online_roulette,
        [LANGUAGE_ZN_CN]: routes.online_roulette,
        [LANGUAGE_AR_AE]: routes.online_roulette,
        [LANGUAGE_JA_JP]: routes.online_roulette
    },
    ['/roulette-en-ligne/']: {
        [LANGUAGE_EN]: routes.online_roulette,
        [LANGUAGE_FR]: '/roulette-en-ligne/',
        [LANGUAGE_FI]: '/online-ruletti/',
        [LANGUAGE_PT_BR]: routes.online_roulette,
        [LANGUAGE_ES_MX]: routes.online_roulette,
        [LANGUAGE_TR_TR]: routes.online_roulette,
        [LANGUAGE_FI_FI]: routes.online_roulette,
        [LANGUAGE_RU_RU]: routes.online_roulette,
        [LANGUAGE_VI_VN]: routes.online_roulette,
        [LANGUAGE_TH_TH]: routes.online_roulette,
        [LANGUAGE_NN_NO]: routes.online_roulette,
        [LANGUAGE_ZN_CN]: routes.online_roulette,
        [LANGUAGE_AR_AE]: routes.online_roulette,
        [LANGUAGE_JA_JP]: routes.online_roulette
    },
    ['/online-ruletti/']: {
        [LANGUAGE_EN]: routes.online_roulette,
        [LANGUAGE_FR]: '/roulette-en-ligne/',
        [LANGUAGE_FI]: '/online-ruletti/',
        [LANGUAGE_PT_BR]: routes.online_roulette,
        [LANGUAGE_ES_MX]: routes.online_roulette,
        [LANGUAGE_TR_TR]: routes.online_roulette,
        [LANGUAGE_FI_FI]: routes.online_roulette,
        [LANGUAGE_RU_RU]: routes.online_roulette,
        [LANGUAGE_VI_VN]: routes.online_roulette,
        [LANGUAGE_TH_TH]: routes.online_roulette,
        [LANGUAGE_NN_NO]: routes.online_roulette,
        [LANGUAGE_ZN_CN]: routes.online_roulette,
        [LANGUAGE_AR_AE]: routes.online_roulette,
        [LANGUAGE_JA_JP]: routes.online_roulette
    },
    [routes.free_casino_games]: {
        [LANGUAGE_EN]: routes.free_casino_games,
        [LANGUAGE_FR]: '/jeux-casino-gratuit/',
        [LANGUAGE_FI]: '/ilmaisista-kasinopeleistä/',
        [LANGUAGE_PT_BR]: routes.free_casino_games,
        [LANGUAGE_ES_MX]: routes.free_casino_games,
        [LANGUAGE_TR_TR]: routes.free_casino_games,
        [LANGUAGE_FI_FI]: routes.free_casino_games,
        [LANGUAGE_RU_RU]: routes.free_casino_games,
        [LANGUAGE_VI_VN]: routes.free_casino_games,
        [LANGUAGE_TH_TH]: routes.free_casino_games,
        [LANGUAGE_NN_NO]: routes.free_casino_games,
        [LANGUAGE_ZN_CN]: routes.free_casino_games,
        [LANGUAGE_AR_AE]: routes.free_casino_games,
        [LANGUAGE_JA_JP]: routes.free_casino_games
    },
    ['/jeux-casino-gratuit/']: {
        [LANGUAGE_EN]: routes.free_casino_games,
        [LANGUAGE_FR]: '/jeux-casino-gratuit/',
        [LANGUAGE_FI]: '/ilmaisista-kasinopeleistä/',
        [LANGUAGE_PT_BR]: routes.free_casino_games,
        [LANGUAGE_ES_MX]: routes.free_casino_games,
        [LANGUAGE_TR_TR]: routes.free_casino_games,
        [LANGUAGE_FI_FI]: routes.free_casino_games,
        [LANGUAGE_RU_RU]: routes.free_casino_games,
        [LANGUAGE_VI_VN]: routes.free_casino_games,
        [LANGUAGE_TH_TH]: routes.free_casino_games,
        [LANGUAGE_NN_NO]: routes.free_casino_games,
        [LANGUAGE_ZN_CN]: routes.free_casino_games,
        [LANGUAGE_AR_AE]: routes.free_casino_games,
        [LANGUAGE_JA_JP]: routes.free_casino_games
    },
    ['/kostenlose-casinospiele/']: {
        [LANGUAGE_EN]: routes.free_casino_games,
        [LANGUAGE_FR]: '/jeux-casino-gratuit/',
        [LANGUAGE_FI]: '/ilmaisista-kasinopeleistä/',
        [LANGUAGE_PT_BR]: routes.free_casino_games,
        [LANGUAGE_ES_MX]: routes.free_casino_games,
        [LANGUAGE_TR_TR]: routes.free_casino_games,
        [LANGUAGE_FI_FI]: routes.free_casino_games,
        [LANGUAGE_RU_RU]: routes.free_casino_games,
        [LANGUAGE_VI_VN]: routes.free_casino_games,
        [LANGUAGE_TH_TH]: routes.free_casino_games,
        [LANGUAGE_NN_NO]: routes.free_casino_games,
        [LANGUAGE_ZN_CN]: routes.free_casino_games,
        [LANGUAGE_AR_AE]: routes.free_casino_games,
        [LANGUAGE_JA_JP]: routes.free_casino_games
    },
    ['/ilmaisista-kasinopeleistä/']: {
        [LANGUAGE_EN]: routes.free_casino_games,
        [LANGUAGE_FR]: '/jeux-casino-gratuit/',
        [LANGUAGE_FI]: '/ilmaisista-kasinopeleistä/',
        [LANGUAGE_PT_BR]: routes.free_casino_games,
        [LANGUAGE_ES_MX]: routes.free_casino_games,
        [LANGUAGE_TR_TR]: routes.free_casino_games,
        [LANGUAGE_FI_FI]: routes.free_casino_games,
        [LANGUAGE_RU_RU]: routes.free_casino_games,
        [LANGUAGE_VI_VN]: routes.free_casino_games,
        [LANGUAGE_TH_TH]: routes.free_casino_games,
        [LANGUAGE_NN_NO]: routes.free_casino_games,
        [LANGUAGE_ZN_CN]: routes.free_casino_games,
        [LANGUAGE_AR_AE]: routes.free_casino_games,
        [LANGUAGE_JA_JP]: routes.free_casino_games
    },
    [routes.free_slot_machines]: {
        [LANGUAGE_EN]: routes.free_slot_machines,
        [LANGUAGE_FR]: '/machines-a-sous-gratuites/',
        [LANGUAGE_FI]: '/ilmaiset-peliautomaatit/',
        [LANGUAGE_PT_BR]: routes.free_slot_machines,
        [LANGUAGE_ES_MX]: routes.free_slot_machines,
        [LANGUAGE_TR_TR]: routes.free_slot_machines,
        [LANGUAGE_FI_FI]: routes.free_slot_machines,
        [LANGUAGE_RU_RU]: routes.free_slot_machines,
        [LANGUAGE_VI_VN]: routes.free_slot_machines,
        [LANGUAGE_TH_TH]: routes.free_slot_machines,
        [LANGUAGE_NN_NO]: routes.free_slot_machines,
        [LANGUAGE_ZN_CN]: routes.free_slot_machines,
        [LANGUAGE_AR_AE]: routes.free_slot_machines,
        [LANGUAGE_JA_JP]: routes.free_slot_machines
    },
    ['/machines-a-sous-gratuites/']: {
        [LANGUAGE_EN]: routes.free_slot_machines,
        [LANGUAGE_FR]: '/machines-a-sous-gratuites/',
        [LANGUAGE_FI]: '/ilmaiset-peliautomaatit/',
        [LANGUAGE_PT_BR]: routes.free_slot_machines,
        [LANGUAGE_ES_MX]: routes.free_slot_machines,
        [LANGUAGE_TR_TR]: routes.free_slot_machines,
        [LANGUAGE_FI_FI]: routes.free_slot_machines,
        [LANGUAGE_RU_RU]: routes.free_slot_machines,
        [LANGUAGE_VI_VN]: routes.free_slot_machines,
        [LANGUAGE_TH_TH]: routes.free_slot_machines,
        [LANGUAGE_NN_NO]: routes.free_slot_machines,
        [LANGUAGE_ZN_CN]: routes.free_slot_machines,
        [LANGUAGE_AR_AE]: routes.free_slot_machines,
        [LANGUAGE_JA_JP]: routes.free_slot_machines
    },
    ['/kostenlose-slots/']: {
        [LANGUAGE_EN]: routes.free_slot_machines,
        [LANGUAGE_FR]: '/machines-a-sous-gratuites/',
        [LANGUAGE_FI]: '/ilmaiset-peliautomaatit/',
        [LANGUAGE_PT_BR]: routes.free_slot_machines,
        [LANGUAGE_ES_MX]: routes.free_slot_machines,
        [LANGUAGE_TR_TR]: routes.free_slot_machines,
        [LANGUAGE_FI_FI]: routes.free_slot_machines,
        [LANGUAGE_RU_RU]: routes.free_slot_machines,
        [LANGUAGE_VI_VN]: routes.free_slot_machines,
        [LANGUAGE_TH_TH]: routes.free_slot_machines,
        [LANGUAGE_NN_NO]: routes.free_slot_machines,
        [LANGUAGE_ZN_CN]: routes.free_slot_machines,
        [LANGUAGE_AR_AE]: routes.free_slot_machines,
        [LANGUAGE_JA_JP]: routes.free_slot_machines
    },
    ['/ilmaiset-peliautomaatit/']: {
        [LANGUAGE_EN]: routes.free_slot_machines,
        [LANGUAGE_FR]: '/machines-a-sous-gratuites/',
        [LANGUAGE_FI]: '/ilmaiset-peliautomaatit/',
        [LANGUAGE_PT_BR]: routes.free_slot_machines,
        [LANGUAGE_ES_MX]: routes.free_slot_machines,
        [LANGUAGE_TR_TR]: routes.free_slot_machines,
        [LANGUAGE_FI_FI]: routes.free_slot_machines,
        [LANGUAGE_RU_RU]: routes.free_slot_machines,
        [LANGUAGE_VI_VN]: routes.free_slot_machines,
        [LANGUAGE_TH_TH]: routes.free_slot_machines,
        [LANGUAGE_NN_NO]: routes.free_slot_machines,
        [LANGUAGE_ZN_CN]: routes.free_slot_machines,
        [LANGUAGE_AR_AE]: routes.free_slot_machines,
        [LANGUAGE_JA_JP]: routes.free_slot_machines
    },
    [routes.casino_guide]: {
        [LANGUAGE_EN]: routes.casino_guide,
        [LANGUAGE_FR]: '/guide-casino/',
        [LANGUAGE_FI]: routes.casino_guide,
        [LANGUAGE_PT_BR]: routes.casino_guide,
        [LANGUAGE_ES_MX]: routes.casino_guide,
        [LANGUAGE_TR_TR]: routes.casino_guide,
        [LANGUAGE_FI_FI]: routes.casino_guide,
        [LANGUAGE_RU_RU]: routes.casino_guide,
        [LANGUAGE_VI_VN]: routes.casino_guide,
        [LANGUAGE_TH_TH]: routes.casino_guide,
        [LANGUAGE_NN_NO]: routes.casino_guide,
        [LANGUAGE_ZN_CN]: routes.casino_guide,
        [LANGUAGE_AR_AE]: routes.casino_guide,
        [LANGUAGE_JA_JP]: routes.casino_guide
    },
    ['/guide-casino/']: {
        [LANGUAGE_EN]: routes.casino_guide,
        [LANGUAGE_FR]: '/guide-casino/',
        [LANGUAGE_FI]: routes.casino_guide,
        [LANGUAGE_PT_BR]: routes.casino_guide,
        [LANGUAGE_ES_MX]: routes.casino_guide,
        [LANGUAGE_TR_TR]: routes.casino_guide,
        [LANGUAGE_FI_FI]: routes.casino_guide,
        [LANGUAGE_RU_RU]: routes.casino_guide,
        [LANGUAGE_VI_VN]: routes.casino_guide,
        [LANGUAGE_TH_TH]: routes.casino_guide,
        [LANGUAGE_NN_NO]: routes.casino_guide,
        [LANGUAGE_ZN_CN]: routes.casino_guide,
        [LANGUAGE_AR_AE]: routes.casino_guide,
        [LANGUAGE_JA_JP]: routes.casino_guide
    },
    [routes.wallet]: {
        [LANGUAGE_EN]: routes.wallet,
        [LANGUAGE_FR]: routes.wallet,
        [LANGUAGE_FI]: routes.wallet,
        [LANGUAGE_PT_BR]: routes.wallet,
        [LANGUAGE_ES_MX]: routes.wallet,
        [LANGUAGE_TR_TR]: routes.wallet,
        [LANGUAGE_FI_FI]: routes.wallet,
        [LANGUAGE_RU_RU]: routes.wallet,
        [LANGUAGE_VI_VN]: routes.wallet,
        [LANGUAGE_TH_TH]: routes.wallet,
        [LANGUAGE_NN_NO]: routes.wallet,
        [LANGUAGE_ZN_CN]: routes.wallet,
        [LANGUAGE_AR_AE]: routes.wallet,
        [LANGUAGE_JA_JP]: routes.wallet
    }
};

export const localizePath = (router) => {
    const isGame = GAME_ROUTER_PATHNAME === router.pathname;
    const { categoryName } = router.query;
    const localizedPage = localizedPathnames[isGame ? `/${categoryName}/` : `${router.pathname}/`];
    let path = router.asPath;
    // replace with correct translation regarding the current locale
    if (localizedPage && !path.includes(localizedPage[router.locale])) {
        const lang = Object.keys(localizedPage).filter((key) => path.includes(localizedPage[key]))[0];
        path = path.replace(localizedPage[lang], localizedPage[router.locale]);
        router.replace(router.pathname, path);
    }
};

export const handleCloseModal = (paramsToBeRemoved = [], shouldReopen = false, options = {}) => {
    const hash = window.location.hash;
    const [asPathRoot, asPathQuery = ''] = router.asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    !shouldReopen && params.delete('modal');

    for (let i = 0; i < paramsToBeRemoved.length; i++) {
        params.delete(paramsToBeRemoved[i]);
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    if (hash) {
        updatedParams = `${updatedParams}${hash}`;
    }

    router.push(
        {
            pathname: router.pathname,
            query: {
                ...omit(router.query, shouldReopen ? paramsToBeRemoved : ['modal', ...paramsToBeRemoved])
            }
        },
        `${asPathRoot}${updatedParams}`,
        options
    );
};

export const handleCloseDepositModal = () => {
    handleCloseModal(['transactionId']);
};

export const handleCloseMenu = () => {
    const hash = window.location.hash;
    const [asPathRoot, asPathQuery = ''] = router.asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    params.delete('menu');

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    if (hash) {
        updatedParams = `${updatedParams}${hash}`;
    }

    router.push(
        {
            pathname: router.pathname,
            query: {
                ...omit(router.query, ['menu'])
            }
        },
        `${asPathRoot}${updatedParams}`
    );
};

export const updateParamValue = (key, value, router) => {
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);
    let query = { ...routerQuery };
    if (value > 1) {
        params.set(key, value);
        query = {
            ...query,
            [key]: value
        };
    } else {
        params.delete(key);
        query = { ...omit(query, [key]) };
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const getModalUrl = (modalName, router, queryParam = 'modal') => {
    const hash = window.location.hash;
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    params.set(queryParam, modalName);
    let query = {
        ...routerQuery,
        [queryParam]: modalName
    };

    const href = {
        pathname,
        query
    };

    let as = `${asPathRoot}?${params.toString()}`;
    if (hash) {
        as = `${as}${hash}`;
    }

    return {
        href,
        as
    };
};

export const getMenuUrl = (menuName, router, hideParam) => {
    const hash = window.location.hash;
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    if (!hideParam) {
        params.set('menu', menuName);
    }
    let query = {
        ...routerQuery,
        menu: menuName
    };

    const href = {
        pathname,
        query
    };
    let as = asPathRoot;
    const stringifiedParams = params.toString();

    if (stringifiedParams) {
        as = `${as}?${stringifiedParams}`;
    }

    if (hash) {
        as = `${as}${hash}`;
    }

    return {
        href,
        as
    };
};

export const getGamesPath = (updatedQuery, router) => {
    const { pathname, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query: updatedQuery
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const getGamesListPath = (key, value, router) => {
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);
    let query = { ...routerQuery };
    if (value) {
        params.set(key, value);
        query = {
            ...query,
            [key]: value
        };
    } else {
        params.delete(key);
        query = { ...omit(query, [key]) };
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const isActiveCasinoMenuItem = (pathname) =>
    [
        routes.games_lobby,
        routes.games,
        routes.recently_played,
        routes.favorite_games,
        routes.slot_machines,
        routes.table_games,
        routes.other_games,
        `${routes.slot_machines}[subcategory]/`,
        `${routes.table_games}[subcategory]/`,
        `${routes.other_games}[subcategory]/`
    ].includes(pathname);

export const isActiveLiveCasinoMenuItem = (pathname) =>
    [routes.live_casino, routes.live_casino_games, routes.live_casino_games_category].includes(pathname);

export const setPaginationPage = (page) => {
    const { href, as } = updateParamValue('page', page, router);
    return router.replace(href, as);
};

export const checkIsFrLocation = (userCountry) => userCountry !== COUNTRY_CODE_CH && isFRLocale(userCountry);

export const shortenedLocale = (locale = '') => locale.split('-')[0] ?? locale.slice(0, 2);

export const localeCountryCapitalized = (locale = '') => {
    const parts = locale.split('-');
    if (parts.length > 1) {
        return `${parts[0]}-${parts[1].toUpperCase()}`;
    } else {
        return locale;
    }
};

export const getRouterLocale = (locale = '') => {
    const capitalizedLocale = localeCountryCapitalized(locale);
    const shortLocale = shortenedLocale(locale);

    return {
        capitalizedLocale,
        shortLocale
    };
};

export const includeRouterContext = (method, ...args) => {
    return method.call(router, ...args);
};
