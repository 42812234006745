import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import FormLoader from '../FormLoader';
import { formatCurrency } from '../../../utils/formatCurrency';
import { getMenuUrl, getModalUrl } from '../../../utils/locationConfig';
import { formatSeconds } from '../../../utils/commonConfig';
import { imageBaseUrl } from '../../../services/http';
import { NAV_ITEMS_TYPE_LINK, MENU_LOGIN, MENU_REGISTER, MODAL_WALLET_PREVIEW, MODAL_WITHDRAW } from '../../../variables';
import routes from '../../../utils/routes.json';
import { isMobile } from 'react-device-detect';
import { useNavItems } from '../../../hooks/useNavItems';

const NavMenu = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const isAuth = useSelector((state) => state.user.isAuth);
    const { balance } = useSelector((state) => state.bank.information);
    const { currency } = useSelector((state) => state.user.information);
    const { firstDepositRemainingTime } = useSelector((state) => state.bank);
    const router = useRouter();
    const filteredNavItems = useNavItems();
    const isDataLoaded = currency && balance.total >= 0;
    const amounts = useMemo(() => {
        const loadingAmount = tr['global.loading'];
        if (isDataLoaded) {
            return {
                cashAmount: formatCurrency(balance.total - balance.bonus, currency),
                bonusAmount: formatCurrency(balance.bonus, currency)
            };
        }
        return {
            cashAmount: loadingAmount,
            bonusAmount: loadingAmount
        };
    }, [balance, currency]);

    return (
        <div className="nav-menu">
            {isAuth ? (
                !isDataLoaded ? (
                    <div className="menu--section amount-data">
                        <FormLoader />
                    </div>
                ) : firstDepositRemainingTime ? (
                    <Fragment>
                        <div className="menu--section amount-data">
                            <div className="first-deposit-info">
                                <div className="gift-icon">
                                    <img src={`${imageBaseUrl}/gift.png`} alt="gift" />
                                </div>
                                <div
                                    className="gift-text"
                                    dangerouslySetInnerHTML={{
                                        __html: tr['deposit.flow.first_deposit.tooltip'].replace(
                                            '##TIME##',
                                            `<span class="timer">${formatSeconds(firstDepositRemainingTime)}</span>`
                                        )
                                    }}
                                />
                            </div>
                            <Link {...getModalUrl('deposit', router)}>
                                <a className="btn btn-submit btn-modern" id="deposit_button">
                                    {tr['header.deposit']}
                                </a>
                            </Link>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="menu--section amount-data">
                            <div className="amount">{amounts.cashAmount}</div>
                            <div>
                                +{amounts.bonusAmount} {tr['header.bonus_money']}
                            </div>
                            <Link {...(isMobile ? getModalUrl(MODAL_WALLET_PREVIEW, router) : { href: routes.wallet })}>
                                <a className="btn btn-purple btn-modern" id="wallet-history-button">
                                    {tr['header.my_wallet']}
                                </a>
                            </Link>
                        </div>
                        <div className="menu--section container-btns">
                            <Link {...getModalUrl(MODAL_WITHDRAW, router)}>
                                <a className="btn btn-submit btn-modern" id="withdrawal_button">
                                    {tr['transaction.type.withdraw']}
                                </a>
                            </Link>
                            <Link {...getModalUrl('deposit', router)}>
                                <a className="btn btn-submit btn-modern" id="deposit_button">
                                    {tr['header.deposit']}
                                </a>
                            </Link>
                        </div>
                    </Fragment>
                )
            ) : (
                <div className="menu--section container-btns">
                    <Link {...getMenuUrl(MENU_LOGIN, router)}>
                        <a className="btn btn-purple btn-modern">{tr['header.login_form.login']}</a>
                    </Link>
                    <Link {...getMenuUrl(MENU_REGISTER, router)}>
                        <a className="btn btn-submit btn-modern">{tr['header.register']}</a>
                    </Link>
                </div>
            )}
            <ul
                style={{ '--nav-item_height': isAuth ? '320px' : '180px', '--nav_length': filteredNavItems.length }}
                className="navigation-list"
            >
                {filteredNavItems.map((item, index) => (
                    <li key={index} className="navigation-list-item">
                        {item.type === NAV_ITEMS_TYPE_LINK ? (
                            <Link href={item.href} as={item.as}>
                                <a {...item.linkProps}>
                                    {item.icon}
                                    <span>{item.label}</span>
                                </a>
                            </Link>
                        ) : (
                            <a {...item.linkProps}>
                                {item.icon}
                                <span>{item.label}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NavMenu;
