import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import cx from 'classnames';
import { logoutUser } from '../store/reducers/user';
import { isActiveCasinoMenuItem, isActiveLiveCasinoMenuItem } from '../utils/locationConfig';
import { NAV_ITEMS_TYPE_LINK, NAV_ITEMS_TYPE_ANCHOR, WHEEL_ORIGIN_HAMBURGER } from '../variables';
import routes from '../utils/routes.json';
import {
    Lobby,
    Casino,
    LiveCasino,
    Promotions,
    Bonuses,
    WheelOfFortune,
    BetRace,
    VipProgram,
    ReferAFriend,
    Profile,
    HelpCenter,
    LogOut
} from '../components/svg-icons/navigation-icons/';
import { setWheelOrigin } from '../store/reducers/wheel-of-fortune';

export const useNavItems = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);
    const isAuth = useSelector((state) => state.user.isAuth);
    const hasLiveGames = useSelector((state) => state.games.hasLiveGames);
    const { isWofEnabled } = useSelector((state) => state.wheel_of_fortune);
    const { featuresAccess } = useSelector((state) => state.user.information);
    const router = useRouter();
    const dispatch = useDispatch();
    const pathname = `${router.pathname}/`;
    const isReferralAllowed = isAuth && featuresAccess?.raf;

    const navItems = [
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.home,
            as: paths[routes.home],
            icon: <Lobby />,
            label: tr['nav.lobby'],
            show: true,
            linkProps: {
                className: cx({ active: router.pathname === routes.home })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.games_lobby,
            as: paths[routes.games_lobby],
            icon: <Casino />,
            label: tr['nav.casino'],
            show: true,
            linkProps: {
                className: cx({ active: isActiveCasinoMenuItem(pathname) })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.live_casino,
            as: paths[routes.live_casino],
            icon: <LiveCasino />,
            label: tr['nav.live_dealer'],
            show: hasLiveGames,
            linkProps: {
                className: cx({ active: isActiveLiveCasinoMenuItem(pathname) })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.promotions,
            as: paths[routes.promotions],
            icon: <Promotions />,
            label: tr['nav.promotions'],
            show: true,
            linkProps: {
                className: cx({ active: pathname === routes.promotions })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.bonuses,
            as: routes.bonuses,
            icon: <Bonuses />,
            label: tr['lobby.bonuses.title'],
            show: isAuth,
            linkProps: {
                className: cx({ active: pathname === routes.bonuses })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.wheel_of_fortune,
            as: routes.wheel_of_fortune,
            icon: <WheelOfFortune />,
            label: tr['nav.wild_fortunes'],
            show: isAuth && isWofEnabled,
            linkProps: {
                onClick: () => dispatch(setWheelOrigin(WHEEL_ORIGIN_HAMBURGER)),
                className: cx({ active: pathname === routes.wheel_of_fortune })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.bet_race,
            as: paths[routes.bet_race],
            icon: <BetRace />,
            label: tr['nav.bet-race'],
            show: true,
            linkProps: {
                className: cx({ active: pathname === routes.bet_race })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.vip,
            as: paths[routes.vip],
            icon: <VipProgram />,
            label: tr['nav.vip_program'],
            show: true,
            linkProps: {
                className: cx({ active: pathname === routes.vip })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.refer_a_friend,
            as: routes.refer_a_friend,
            icon: <ReferAFriend />,
            label: tr['nav.refer_a_friend'],
            show: isReferralAllowed,
            linkProps: {
                className: cx({ active: pathname === routes.refer_a_friend })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: routes.account_profile,
            as: routes.account_profile,
            icon: <Profile />,
            label: tr['account.nav_profile'],
            show: isAuth,
            linkProps: {
                className: cx({ active: pathname === routes.account_profile })
            }
        },
        {
            type: NAV_ITEMS_TYPE_LINK,
            href: tr['nav.help_center.link'],
            as: tr['nav.help_center.link'],
            icon: <HelpCenter />,
            label: tr['nav.help_center'],
            show: true,
            linkProps: {
                target: '_blank',
            }
        },
        {
            type: NAV_ITEMS_TYPE_ANCHOR,
            icon: <LogOut />,
            label: tr['header.logout'],
            show: isAuth,
            linkProps: {
                onClick: () => {
                    router
                        .replace({
                            query: { ...omit(router.query, ['menu']) }
                        })
                        .then(() => dispatch(logoutUser()));
                },
                'data-testid': 'player_logout',
                id: 'logout_btn'
            }
        }
    ];

    return navItems.filter(item => item.show);
};
